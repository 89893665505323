import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "author": "Macey Wong",
  "date": "2016-05-17 UTC -8",
  "length": 5,
  "location": "Portland, OR",
  "path": "/jonah-birthday/",
  "title": "Welcome Jonah Wong!",
  "tags": ["personal", "boys", "lifestyle"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We don't make mistakes we just have happy little accidents. Just go back and put one little more happy tree in there. We'll throw some old gray clouds in here just sneaking around and having fun. And just raise cain. There is immense joy in just watching - watching all the little creatures in nature.`}</p>
    <p>{`We tell people sometimes: we're like drug dealers, come into town and get everybody absolutely addicted to painting. It doesn't take much to get you addicted. Be brave. We'll play with clouds today.`}</p>
    <p>{`You have to make those little noises or it won't work. There is no right or wrong - as long as it makes you happy and doesn't hurt anyone. If what you're doing doesn't make you happy - you're doing the wrong thing. You are only limited by your imagination.`}</p>
    <p>{`But we're not there yet, so we don't need to worry about it. Let's have a nice tree right here. We'll have a super time. It's hard to see things when you're too close. Take a step back and look. Let your imagination be your guide.`}</p>
    <p>{`We don't want to set these clouds on fire. We have all at one time or another mixed some mud. That's a crooked tree. We'll send him to Washington. You can create anything that makes you happy. These things happen automatically. All you have to do is just let them happen.`}</p>
    <p>{`Maybe there's a happy little bush that lives right there. You want your tree to have some character. Make it special. Imagination is the key to painting. A fan brush is a fantastic piece of equipment. Use it. Make friends with it.`}</p>
    <p>{`We must be quiet, soft and gentle. It's important to me that you're happy. You can't have light without dark. You can't know happiness unless you've known sorrow. How do you make a round circle with a square knife? That's your challenge for the day.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgUA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAVRiUoOOP//EABoQAQACAwEAAAAAAAAAAAAAAAECAwARExL/2gAIAQEAAQUC9GdTTYLTqx5kYSIj/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BGf/EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8BnFv/xAAcEAACAQUBAAAAAAAAAAAAAAAAAQIQERIhIjH/2gAIAQEABj8CUlXBaZaXR4f/xAAYEAADAQEAAAAAAAAAAAAAAAAAAREhQf/aAAgBAQABPyFoM91KCmga2vomDab6Rh//2gAMAwEAAgADAAAAELPP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAwEBPxBD2FP/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFR/9oACAECAQE/EArti//EABsQAQEAAwEBAQAAAAAAAAAAAAERACExQaHh/9oACAEBAAE/EAd6o6qk8MY4gS3XcaCzAlZo0QT5HeTGs06vDnP3O3oe5//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Jonah Birthday",
          "title": "Jonah Birthday",
          "src": "/static/9181102e086b395521b8d8b70cd9a707/8e1fc/jonah_birthday_1.jpg",
          "srcSet": ["/static/9181102e086b395521b8d8b70cd9a707/863e1/jonah_birthday_1.jpg 225w", "/static/9181102e086b395521b8d8b70cd9a707/20e5d/jonah_birthday_1.jpg 450w", "/static/9181102e086b395521b8d8b70cd9a707/8e1fc/jonah_birthday_1.jpg 900w", "/static/9181102e086b395521b8d8b70cd9a707/b768e/jonah_birthday_1.jpg 1350w", "/static/9181102e086b395521b8d8b70cd9a707/d9c39/jonah_birthday_1.jpg 1800w", "/static/9181102e086b395521b8d8b70cd9a707/768c6/jonah_birthday_1.jpg 3264w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAUWGiU9FqP/EABsQAQACAgMAAAAAAAAAAAAAAAIAAwEREhMi/9oACAEBAAEFAkuWO4aT9ZjrNdelP//EABYRAQEBAAAAAAAAAAAAAAAAAAAREv/aAAgBAwEBPwHSP//EABgRAAIDAAAAAAAAAAAAAAAAAAABAhFR/9oACAECAQE/AXDS0f/EABoQAQACAwEAAAAAAAAAAAAAAAABIQIREjH/2gAIAQEABj8C2x6Sp7aH/8QAGBABAQEBAQAAAAAAAAAAAAAAAREAIUH/2gAIAQEAAT8hlPzkdEkrOzdA9dVRJ5qBhnSW5n6oO//aAAwDAQACAAMAAAAQ28//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAEDAQE/EFWFH//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEUH/2gAIAQIBAT8QEAmHb//EABwQAQADAQADAQAAAAAAAAAAAAEAESFBMVFhcf/aAAgBAQABPxBgYI0XW8gUp8Raz5HWA0cjTU9Owi91zLZX5ctR0lnSf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Jonah Birthday",
          "title": "Jonah Birthday",
          "src": "/static/ee2e7098d26c245bd18a0bf0406139d2/8e1fc/jonah_birthday_2.jpg",
          "srcSet": ["/static/ee2e7098d26c245bd18a0bf0406139d2/863e1/jonah_birthday_2.jpg 225w", "/static/ee2e7098d26c245bd18a0bf0406139d2/20e5d/jonah_birthday_2.jpg 450w", "/static/ee2e7098d26c245bd18a0bf0406139d2/8e1fc/jonah_birthday_2.jpg 900w", "/static/ee2e7098d26c245bd18a0bf0406139d2/b768e/jonah_birthday_2.jpg 1350w", "/static/ee2e7098d26c245bd18a0bf0406139d2/d9c39/jonah_birthday_2.jpg 1800w", "/static/ee2e7098d26c245bd18a0bf0406139d2/768c6/jonah_birthday_2.jpg 3264w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      